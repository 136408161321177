.popupDetail {
    padding: 4px;
    font-size: 14px;
    font-weight: 800;
}

/** test commit **/
/* .MuiSpeedDialAction-staticTooltipLabel {
    width: auto!important;
    word-break:normal;
    height: 35px;
} */

.z-index-max {
    z-index: 99999!important;
}

.dwn-position {
    z-index: 999!important;
    top: 110px;
    right: 9px!important;
    float: right!important;
    margin-top: 10px;
    position: absolute!important;
    clear: both!important;
    margin-top: 20px;
}

.zoom-position {
    z-index: 999!important;
    top: 12px;
    right: 5px!important;
    float: right!important;
    clear: both!important;
    width: 53px;
    position: absolute!important;
}

.dwn-width {
    width: 200px!important;
}

.topbar {
    position: relative;
    top: 0;
}

.add-photo-icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 34px !important;
    height: 29px !important;
    background-color: #f4f4f4;
    border-radius: 4px;
}

.weather-temperature {
    position: absolute;
    left: 37px;
    top: 12px;
}

.map-icon-custom {
    background-color: #789f8e!important;
    width: 48px!important;
    height: 48px!important;
}
.map-filtericon-custom {
    background-color: #789f8e!important;
    width: 40px !important;
    height: 40px !important;
    color:white !important;
    min-width: 0px !important;
    padding: 2px;
}

.leaflet-control-layers {
    box-shadow: none!important;
    background: #789f8e!important;
    border-radius: 4px!important;
    padding: 2px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.leaflet-control-layers-toggle {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAQYHKROcvU3oAAACAElEQVRIx6WVPU9TYRiGnxZOmkYSbKJoAzToIA4UFp104GOwQfoX+hPaJgxuTUcmTEcHljM24GKiiSEkTrooxEAihJImRMFoQ4giGnJ6MfR8n55jD+can/e9n+R97+dDxBdSLPOTFs9JSViIUeA7Bi1K9IWRP+A9bj7yqDfxbV6g0Y02dUaDxQolTgniN1USfvJZtumFPea94lHUnsQGr7hriZNUOQ8lB/hHjQERIUsztNigSTbGlkyFLhOLzbjciiAXuROXpUgJqiJCjt0r/cAuud4LyI1VUORpUKSftG8Ju2lTJ0M/RRosCAcAfOGJCA/58F/5Jx6LMM1nAPaFhnlUJ0Pc0cZuWpToI0PdjHwTFmxVeEaFJClqXHjEGio3SVLhzIyd89TbB4cURLjPW4f8HVP6f1kY/cCgpxM3mBAhrxf5VwrEuMdr24095jtKoYzGCkMuKy+oMUiCHDkSXKPKX6eFDLGCRlE4AuCEMgppVNrmxWMqzDBDhWObhSppFMqcAHAkbJqHO8z5zEOnhXPsWBF3O68y5mNlx8IxVm2xJhPegfKHJQa47rBSQ+UGSZ7xy4wZA0XvhpEuVk6yDsA6kyLk9Zp1WhgwVDfIijDMsAjjvHFb2BUUFh1WrlGizEvbY05ZRAmcEJEWi5kkymrTU0RbrnqSznr/wXLQer8EWwHBE6JE+rsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDEtMDZUMDc6NDE6MTkrMDA6MDDztdTnAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAxLTA2VDA3OjQxOjE5KzAwOjAwguhsWwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=)!important;
    width: 36px;
    height: 36px;
}

.MuiSpeedDial-root>.MuiFab-primary {
    background-color: #789f8e!important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: #e1e3e2!important;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
    font-weight: 600!important;
    color: #fff!important;
    font-size: 14px;
}

.marker-save-btn {
    z-index: 99999;
    position: absolute;
    width: 100%;
    background: #fff;
    padding: 2px;
    align-content: flex-end;
    position: fixed;
    bottom: 4px;
}

.fl-rgt {
    float: right;
}

.marker-cancel-btn {
    margin-right: 8px!important;
    background-color: #6b6969!important;
    color: #fff!important;
    float: right!important;
}

.marker-hint-msg {
    padding: 16px 0;
    margin-left: 5px;
    font-weight: 600;
}

.marker-hint-arrow {
    float: left;
    margin-right: 5px;
    margin-top: -4px;
}

.delete-marker-link {
    color: #433c3c!important;
    cursor: pointer;
    position: absolute;
    bottom: 4px;
    right: 3px;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    color: #006633!important;
}

.add-marker-icon {
    color: #006633!important;
    background-color: #fff!important;
}

.hunt-record-icon {
    color: #006633!important;
    background-color: #fff!important;
}

#SpeedDialtooltip-action-0-label {
    min-width: 120px!important;
}

#SpeedDialtooltip-action-1-label {
    min-width: 160px!important;
}

.leaflet-popup-content-wrapper {
    border-radius: 4px!important;
}

.leaflet-popup-content {
    margin: 2px 4px!important;
    line-height: 1.4!important;
}

.pls-wt {
    color: #888888;
    font-size: 1.5rem;
    margin-top: 16px;
    text-align: center;
}

.textStyle {
    padding: 5px 16px;
}

.iframe-style {
    overflow: auto;
}

.img-one {
    right: -8px;
    position: absolute;
    top: -8px;
    background-color: #006633;
    color: white;
    border-radius: 14px;
    padding: 2px;
    width: 21px !important;
    height: 21px !important;
}

.img-two {
    right: -8px;
    position: absolute;
    top: -8px;
    background-color: #006633;
    color: white;
    border-radius: 14px;
    padding: 2px;
    width: 21px !important;
    height: 21px !important;
}

.img-three {
    right: -8px;
    position: absolute;
    top: -8px;
    background-color: #006633;
    color: white;
    border-radius: 14px;
    padding: 2px;
    width: 21px !important;
    height: 21px !important;
}

.cam-icon-one {
    right: 5px;
    position: absolute !important;
    bottom: 5px;
    background-color: #006633;
    color: white;
    border-radius: 18px;
    padding: 4px;
    height: 31px !important;
    width: 32px !important;
}

.cam-icon-two {
    right: 5px;
    position: absolute !important;
    bottom: 5px;
    height: 24px !important;
    width: 26px !important;
    background-color: #006633;
    color: white;
    border-radius: 18px;
    padding: 2px;
}

.cam-icon-three {
    right: 5px;
    position: absolute !important;
    bottom: 5px;
    height: 24px !important;
    width: 26px !important;
    background-color: #006633;
    color: white;
    border-radius: 18px;
    padding: 2px;
}


/***********************Hunt Record ********************/

.MuiLinearProgress-root {
    height: 8px!important;
}

.hunt-record-checkbox {
    top: 2px;
    position: absolute;
    cursor: pointer!important;
    z-index: 999;
    right: 0;
}

.hunter-card {
    padding: 6px 12px 0px!important;
    border: 1px solid gray;
    border-radius: 4px;
    /* margin-left: 28px; */
    cursor: pointer;
    max-width: 58px;
    float: right;
    margin-top: 7px;
    max-height: 53px;
}

.hunter-card>div {
    position: relative;
    margin-left: -4px;
    top: -8px;
}

.hunter-card-edit-icon {
    position: absolute;
    float: right;
    top: 30px;
    right: -32px;
    cursor: pointer!important;
    z-index: 999;
}

.hunter-card-delete-icon {
    float: right;
    top: -66px;
    right: -8px;
    position: relative;
    cursor: pointer!important;
    z-index: 999;
}

#record-form-grid-id>.MuiGrid-spacing-xs-1>.MuiGrid-item {
    padding: 1px 6px!important;
}

#record-form-grid-id>.makeStyles-formControl-204 {
    margin-bottom: 2px!important;
}

#record-form-grid-id .MuiFormControl-marginNormal {
    margin-top: 4px!important;
    margin-bottom: 4px!important;
}

.MuiTreeItem-content {
    display: list-item !important;
}

.MuiTreeItem-label {
    width: 102%!important
}

.MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54) !important;
    cursor: default;
}


/**************Hnting Plan ****************/

.filter-icon-hp {
    width: 25px;
    /* float: right; */
    position: absolute;
    right: 25px;
    clear: both;
}

.span-item {
    width: 30%;
    font-weight: 400;
    word-break: break-all;
    border-right: 1px solid #bcb8b8;
    color: #474545;
    height: auto;
    padding: 4px 2px;
    margin-right: 2px;
    padding-right: 5px;
    padding-left: 4px;
}

.filter-value {
    /* max-width: 50%;
    background: #e6e6e6;
    border-radius: 7px;
    padding: 8px 4px 4px 4px;
    margin-left: 4px; */
    max-width: 70%;
    background: #e6e6e6;
    border-radius: 7px;
    padding: 8px 1px 0px 4px;
    margin: 2px 7px;
    height: 36px;
}

;
.pd40 {
    padding-left: 40px!important;
}

.pd10-item {
    padding: 3px 0px 3px 6%;
    border-left: 1px solid #e5e5e5;
    min-width: 14%;
}

.record-item-big {
    width: 22%;
    padding-left: 22px;
}

.shooting-list-header-1 {
    margin-right: 44px !important;
    padding-left: 5px !important;
}

.shooting-list-header-2 {
    margin-right: 5px !important;
}


.record-item {
    width: 15%;
    padding: 1px 1px 1px 1px;
}

.pd-count {
    padding-left: 5%!important;
}

.leaflet-control-layers-overlays label {
    padding: 6px 3px!important;
}

.note {
    color: red;
    font-size: 13px;
}

.animalIcon {
    /* background-color: #dadada; */
    /* border-radius: 2px; */
    /* padding: 9px; */
    /* margin: 2px 2px; */
    /* border: 2px solid white; */
}

.rotateimg180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.item-hunter-name {
    padding-left: 20px;
    width: 28%;
    color: #000;
}

.item-pd10 {
    padding-left: 3%;
}

.MuiOutlinedInput-adornedEnd {
    padding-right: 0px!important;
}

.pd-lft{
padding-left: 7%!important;
}

.pd-lft-4{
    padding-left: 4%!important;
}

.filter-box{
    z-index: 99;
    position: sticky;
    top: 60px;
    background-color: #fff;
    padding-left: 10px;
}

.header-box{
    z-index: 99;
    position: sticky;
    top:100px;
}

.popup-content{
    background-color: #006633!important;
    margin-bottom:4px!important;
}
.popup-content-update{
    background-color: #006633!important;
    margin-bottom:7px!important;
}

.popup-btn {
    color: #fff!important;
    border: 1px solid!important;
    margin-right: 7px!important;
}

.active-user-valid{
    font-size: 12px!important;
    font-weight: 500!important;
    color: #1aae64;
}

.offline-records-sec{
 margin-top: 35px!important;
}

.item-mobile-view-offline label{
    font-size: 14px;
    font-weight: 600;
}

.item-mobile-view-offline ul {
    list-style-type: none;
}

.item-mobile-view-offline ul li{
    background-color: #888a88;
    color: #fff;
    margin-left: -20px;
    padding: 5px;
}



/* ----------- Non-Retina Screens -----------*/

@media screen and (min-device-width: 1200px)  and (-webkit-min-device-pixel-ratio: 1) {
    .pd-count {
        padding-left: 4%!important;
    }
    .record-item-big {
        width: 25%;
    }
    .record-item {
        width: 16%;
    }
    .item-pd10 {
        padding-left: 10%;
    }
    .item-mobile-view-offline{
        max-width: 40%!important;
        margin: 35px 30%!important;
    }
    .item-mobile-view{
        max-width: 40%!important;
        margin: 0 30%!important;
    }
    .pad-left-null{
        padding: 0!important;
    }
    .open-close{
        right: 30%!important;
    }
    .pd-lft{
        padding-left: 13%!important;
        }
        .mrgin-tp-10{
            margin-top: 10px!important;
            margin-bottom: 50px!important;
        }
        .filter-box{
            top:56px!important;
        }
        .pd-lft-4{
            padding-left: 4%!important;
        }
        
}


/* ----------- Retina Screens ----------- */

@media screen and (min-device-width: 1200px)  {
    .pd-count {
        padding-left: 4%!important;
    }
    .record-item-big {
        width: 25%;
    }
    .record-item {
        width: 16%;
    }
    .item-pd10 {
        padding-left: 10%;
    }
    .item-mobile-view{
        max-width: 40%!important;
        margin: 0 30%!important;
        
    }
    .item-mobile-view-offline{
        max-width: 40%!important;
        margin: 35px 30%!important;
    }
    .pad-left-null{
        padding: 0!important;
    }
    .open-close{
        right: 30%!important;
    }
    .pd-lft{
        padding-left: 13%!important;
        }
        .mrgin-tp-10{
            margin-top: 10px!important;
            margin-bottom: 50px!important;
        }
        .filter-box{
            top:56px!important;
        }
       
        
}


/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
    .pd-count {
        padding-left: 4%!important;
    }
    .record-item-big {
        width: 25%;
    }
    .record-item {
        width: 16%;
    }
    .item-pd10 {
        padding-left: 10%;
    }
    .item-mobile-view{
        max-width: 40%!important;
        margin: 0 30%!important;
        
    }
    .item-mobile-view-offline{
        max-width: 40%!important;
        margin: 35px 30%!important;
    }
    .pad-left-null{
        padding: 0!important;
    }
    .open-close{
        right: 30%!important;
    }
    .pd-lft{
        padding-left: 13%!important;
        }
        .mrgin-tp-10{
            margin-top: 10px!important;
            margin-bottom: 50px!important;
        }
        .filter-box{
            top:56px!important;
        }
    
  }
  

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    .pd-count {
        padding-left: 4%!important;
    }
    .record-item-big {
        width: 25%;
    }
    .record-item {
        width: 16%;
    }
    .item-pd10 {
        padding-left: 10%;
    }
    .item-mobile-view{
        max-width: 40%!important;
        margin: 0 30%!important;
        
    }
    .item-mobile-view-offline{
        max-width: 40%!important;
        margin: 35px 30%!important;
    }
    .pad-left-null{
        padding: 0!important;
    }
    .open-close{
        right: 30%!important;
    }
    .pd-lft{
        padding-left: 13%!important;
        }
        .mrgin-tp-10{
            margin-top: 10px!important;
            margin-bottom: 50px!important;
        }
        .filter-box{
            top:56px!important;
        }
    
  }

.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: -3px !important;
    contain: content;
}


/* ----------- Screens under 768px ----------- */
@media screen and (max-device-width: 768px)  {
    .shooting-list-whitespace {
        width: 0 !important;
    }
}

