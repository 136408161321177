.MuiSpeedDial-root.MuiSpeedDial-directionUp {
  align-items: flex-end;
}

.MuiSpeedDialAction-staticTooltip.MuiSpeedDialAction-tooltipPlacementLeft {
  justify-content: flex-end;
  cursor: pointer;
}

.MuiSpeedDialAction-staticTooltip.MuiSpeedDialAction-tooltipPlacementLeft .MuiSpeedDialAction-staticTooltipLabel {
  position: unset;
  cursor: pointer;
  user-select: none;
}