.marker {
    position: relative;
}

.marker .outline {
    position: absolute;
    top: -4px;
    left: -8px;
    width: 40px;
    height: 40px;
    transform: scaleX(0.55) rotate(45deg);
    border: 4px solid #d60404;
    border-top: none;
    border-left: none;
}

.marker .top {
    position: relative;
    width: 100%;
    height: 20px;
    border-top-left-radius: calc(24px);
    border-top-right-radius: calc(24px);
    border: 3px solid #d60404;
    border-bottom: none;
}

.marker .clock {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true' role='presentation' stroke='%2314aa5f' fill='%2314aa5f'%3E%3Ccircle cx='12' cy='12' r='10' fill='%23006633'%3E%3C/circle%3E%3Cpath d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'%3E%3C/path%3E%3Cpath d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z'%3E%3C/path%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    margin-left: 14px;
    background-repeat: none;
}